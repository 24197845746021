import React, { useLayoutEffect } from 'react';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import GareImage from "../gare.jpeg";
import ReservationButton from "../Components/ReservationButton";
import ExpandableSection from "../Components/ExpandableText";

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '20px',
        marginBottom: '10px',
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '20px',
        textAlign: 'center',
    },
    image: {
        maxWidth: '80%', // Réduire la largeur de l'image sur les appareils mobiles
        height: 'auto', // Laisser la hauteur s'ajuster automatiquement pour maintenir le ratio
        maxHeight: '400px', // Hauteur maximale de l'image (à ajuster selon vos besoins)
    },
}));

export function Gare() {
    const classes = useStyles();
    useLayoutEffect(() => {
        // Fait défiler la page vers le haut lorsque le composant est monté
        window.scrollTo(0, 0);
    }, []); // Le tableau vide [] en tant que dépendance signifie que cela ne s'exécute qu'une seule fois après le premier rendu

    return (
        <div className={classes.container}>
            <Typography variant="h4" style={{ textAlign: 'center', marginBottom: '20px' }}>Gare</Typography>

            <div className={classes.imageContainer}>
                <Link to="/"> {/* Lien vers la racine du site */}
                    <img
                        src={GareImage}
                        alt={process.env.REACT_APP_BUSINESS_NAME}
                        className={classes.image}
                    />
                </Link>
            </div>
            <ExpandableSection>
                <Typography variant="body1" paragraph>
                    C’est avec un grand professionnalisme que notre équipe assurera votre prise en charge à la gare.
                    Votre chauffeur privé vous attendra 10 minutes avant l’arrivée du train pour vous conduire
                    à votre lieu de rendez-vous (domicile, hôtel, bureau) et se chargera de vos bagages, veillant
                    également à ce que vous soyez bien installé.
                </Typography>
                <Typography variant="body1" paragraph>
                    Pour faciliter le contact et identifier votre chauffeur à la sortie de la gare, ce dernier sera
                    porteur d’un panneau indiquant votre nom ou celui de votre client ou d’une entreprise.
                </Typography>
                <Typography variant="body1" paragraph>
                    Dans le cas d’un retard de train, votre chauffeur ajustera votre horaire de prise en charge
                    en fonction de l’arrivée du train, dont vous nous aurez préalablement communiqué le numéro au
                    moment de votre réservation et aucun frais supplémentaire ne vous sera facturé.
                </Typography>
                <Typography variant="body1" paragraph>
                    Pour profiter de nos services de transferts de et vers la gare SNCF, réservez dès maintenant
                    notre chauffeur privé.
                </Typography>
            </ExpandableSection>
            <ReservationButton
                buttonText="Réserver"
                phoneNumber={process.env.REACT_APP_BUSINESS_PHONE}
            />
        </div>
    );
}
