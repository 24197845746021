import React, { useLayoutEffect } from 'react';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import AeroportImage from "../aerport.jpeg";
import ReservationButton from "../Components/ReservationButton";
import ExpandableSection from "../Components/ExpandableText";

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '20px', // Ajoute de l'espace au-dessus du container principal
        marginBottom: '10px', // Ajoute de l'espace en dessous du container principal
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center', // Centrage vertical
        marginBottom: '20px', // Ajoute de l'espace en dessous de l'image
    },
    image: {
        maxWidth: '100%', // Assurez-vous que l'image ne dépasse pas la largeur du conteneur
        height: 'auto', // Hauteur ajustée automatiquement pour maintenir le ratio
        maxHeight: '400px', // Hauteur maximale de l'image (à ajuster selon vos besoins)
    },
}));

export function Aeroport() {
    const classes = useStyles();

    useLayoutEffect(() => {
        // Fait défiler la page vers le haut lorsque le composant est monté
        window.scrollTo(0, 0);
    }, []); // Le tableau vide [] en tant que dépendance signifie que cela ne s'exécute qu'une seule fois après le premier rendu


    return (
        <>
            <Typography variant="h4" style={{ textAlign: 'center', marginBottom: '20px' }}>Aéroport</Typography>
            <div className={classes.imageContainer}>
                <Link to="/"> {/* Lien vers la racine du site */}
                    <img
                        src={AeroportImage}
                        alt={process.env.REACT_APP_BUSINESS_NAME}
                        className={classes.image}
                    />
                </Link>
            </div>
            <ExpandableSection>
                <Typography variant="body1" paragraph>
                    {process.env.REACT_APP_BUSINESS_NAME} peut s’occuper de tous vos transferts depuis et vers les aéroports
                    de la région et au-delà, notre objectif, c’est de faire en sorte que vous puissiez
                    voyager sereinement. Nous vous proposons de réaliser le trajet de ou vers l’aéroport
                    à bord d’un véhicule haut de gamme, confortable et propre.
                </Typography>
                <Typography variant="body1" paragraph>
                    Votre chauffeur privé vous fournira une prestation de grande qualité. Il sera discret,
                    ponctuel et courtois. Il a pour mot d’ordre de vous conduire au terminal de l’aéroport
                    à temps et ceci dans des conditions de confort extrêmes, il prendra en charge vos
                    bagages.
                </Typography>
                <Typography variant="body1" paragraph>
                    Pour faciliter le contact et identifier votre chauffeur à la sortie de la zone de
                    débarquement, ce dernier sera porteur d’un panneau indiquant votre nom ou celui de
                    votre client ou d’une entreprise.
                </Typography>
                <Typography variant="body1" paragraph>
                    Pour profiter de nos services de transferts aéroport, réservez dès maintenant notre chauffeur
                    privé.
                </Typography>
            </ExpandableSection>
            <ReservationButton
                buttonText="Réserver"
                phoneNumber={process.env.REACT_APP_BUSINESS_PHONE}
            />
        </>
    );
}
