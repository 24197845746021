import React from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import CallButton from './CallButton'; // Importez le composant CallButton si nécessaire
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: '8px',
    },
}));

const ReservationButton = ({ buttonText, phoneNumber }) => {
    const classes = useStyles();

    return (
        <>
            <div className={classes.buttonContainer}>
                {/* Bouton de réservation */}
                <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to="/reserver"
                    style={{ backgroundColor: '#D4AF37', '&:hover': { backgroundColor: '#c39b33' } }}
                >
                    {buttonText}
                </Button>
            </div>
            <div className={classes.buttonContainer}>
                {/* Bouton d'appel */}
                <CallButton phoneNumber={phoneNumber} />
            </div>
        </>
    );
};

export default ReservationButton;
