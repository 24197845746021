import React from 'react';
import { Button } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import '../Styles/callbutton.css';

const formatPhoneNumber = (phoneNumber) => {
    // Supprimer tous les caractères non numériques
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    // Ajouter les espaces pour formater le numéro de téléphone
    const formatted = cleaned.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5');
    return formatted;
};

const CallButton = ({ phoneNumber }) => {
    const handleCall = () => {
        window.location.href = `tel:${phoneNumber}`;
    };

    return (
        <Button
            variant="contained"
            color="success"
            startIcon={<PhoneIcon />} // Icône de téléphone à gauche du texte
            onClick={handleCall}
            style={{ backgroundColor: '#D4AF37', color: 'white' }}
            className="call-button"
        >
            Appeler {formatPhoneNumber(phoneNumber)}
        </Button>
    );
};

export default CallButton;
