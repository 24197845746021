import React, {useLayoutEffect} from 'react';
import {Typography} from '@mui/material';
import {Link} from 'react-router-dom';
import {makeStyles} from '@mui/styles';
import ParcImage from "../parc.png";
import ReservationButton from "../Components/ReservationButton";
import ExpandableContent from "../Components/ExpandableText";

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '20px', // Ajoute de l'espace au-dessus du container principal
        marginBottom: '10px', // Ajoute de l'espace en dessous du container principal
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center', // Centrage vertical
        marginBottom: '20px', // Ajoute de l'espace en dessous de l'image
    },
    image: {
        maxWidth: '100%', // Assurez-vous que l'image ne dépasse pas la largeur du conteneur
        height: 'auto', // Hauteur ajustée automatiquement pour maintenir le ratio
        maxHeight: '400px', // Hauteur maximale de l'image (à ajuster selon vos besoins)
    },
}));

export function ParcAttraction() {
    const classes = useStyles();
    useLayoutEffect(() => {
        // Fait défiler la page vers le haut lorsque le composant est monté
        window.scrollTo(0, 0);
    }, []); // Le tableau vide [] en tant que dépendance signifie que cela ne s'exécute qu'une seule fois après le premier rendu

    return (
        <>
            <Typography variant="h4" style={{textAlign: 'center', marginBottom: '20px'}}>Les parcs
                d'attractions</Typography>
            <div className={classes.imageContainer}>
                <Link to="/"> {/* Lien vers la racine du site */}
                    <img
                        src={ParcImage}
                        alt={process.env.REACT_APP_BUSINESS_NAME}
                        className={classes.image}
                    />
                </Link>
            </div>
            <ExpandableContent>
                <Typography variant="body1" paragraph>
                    Au départ de Nantes, nous pouvons vous conduire vers les parcs d’attractions de la région, ainsi
                    que les principaux parcs d’attractions français et européens, Disneyland, Astérix, Futuroscope,
                    Centerparc…
                </Typography>
                <Typography variant="body1" paragraph>
                    <a href="https://parc-loisir.fr/parcs-dattractions-pays-de-loire/" target="_blank"
                       rel="noopener noreferrer">Les parcs d’attractions en Pays de la Loire</a>
                </Typography>

                <Typography variant="body1" paragraph>
                    Retrouvez la liste des principaux parcs d’attractions et de loisirs situés dans la région Pays de la
                    Loire. Pour chacun des parcs, vous avez également accès à un guide complet comprenant toutes
                    les informations pratiques.
                </Typography>

                <Typography variant="body1" paragraph>
                    <a href="https://www.puydufou.com/france/fr" target="_blank" rel="noopener noreferrer">Puy du
                        Fou</a>
                </Typography>

                <Typography variant="body1" paragraph>

                    Décors pittoresques, spectacles majestueux, animations atypiques,… le Puy du Fou invite ses
                    visiteurs à voyager dans le temps pour découvrir les aventures palpitantes des preux chevaliers,
                    soldats et héros de guerre qui ont façonné l’histoire de la région.
                </Typography>
                <Typography variant="body1" paragraph>
                    <a href="https://www.terrabotanica.fr/" target="_blank" rel="noopener noreferrer">Terra Botanica</a>
                </Typography>
                <Typography variant="body1" paragraph>
                    Implanté aux portes d’Angers, en Maine-et-Loire, Terra Botanica est le premier parc dédié au
                    végétal en Europe. Tout en mettant en avant le territoire angevin, il propose à ses visiteurs de
                    s’immerger dans un splendide univers botanique, façonné par plus de 275 000 espèces
                    exceptionnelles de par le monde.
                </Typography>
            </ExpandableContent>
            {/* Ajoutez les autres parcs d'attractions avec des balises <Typography> et des liens <a> similaires */}
            <ReservationButton
                buttonText="Réserver"
                phoneNumber={process.env.REACT_APP_BUSINESS_PHONE}
            />
        </>
    );
}
