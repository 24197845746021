import React, {useLayoutEffect} from 'react';
import {Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import MariageImage from "../mariage.png";
import {Link} from 'react-router-dom';
import ReservationButton from "../Components/ReservationButton";
import ExpandableContent from "../Components/ExpandableText";

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '20px', // Ajoute de l'espace au-dessus du container principal
        marginBottom: '10px', // Ajoute de l'espace en dessous du container principal
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center', // Centrage vertical
        marginBottom: '20px', // Ajoute de l'espace en dessous de l'image
        textAlign: 'center',
    },
    image: {
        maxWidth: '80%', // Réduire la largeur de l'image sur les appareils mobiles
        height: 'auto', // Laisser la hauteur s'ajuster automatiquement pour maintenir le ratio
        maxHeight: '400px', // Hauteur maximale de l'image (à ajuster selon vos besoins)
    },
}));

export function Mariage() {
    const classes = useStyles();
    useLayoutEffect(() => {
        // Fait défiler la page vers le haut lorsque le composant est monté
        window.scrollTo(0, 0);
    }, []); // Le tableau vide [] en tant que dépendance signifie que cela ne s'exécute qu'une seule fois après le premier rendu

    return (
        <>
            <div className={classes.container}>
                <Typography variant="h4" style={{textAlign: 'center', marginBottom: '20px'}}>Mariage et mise à
                    disposition</Typography>
                <div className={classes.imageContainer}>
                    <Link to="/"> {/* Lien vers la racine du site */}
                        <img
                            src={MariageImage}
                            alt={process.env.REACT_APP_BUSINESS_NAME}
                            className={classes.image}
                        />
                    </Link>
                </div>
                <ExpandableContent>
                    <Typography variant="body1" paragraph>
                        Vous êtes désireux de faire de votre mariage ou tout autre évènement une journée
                        inoubliable aussi bien pour vous que pour vos convives, il ne faut pas négliger la question
                        logistique transport. Pour ce faire {process.env.REACT_APP_BUSINESS_NAME} met à votre
                        disposition,
                        son expertise
                        et sa flotte de véhicule haut de gamme avec un chauffeur privé compétent et discret, il sera à
                        votre disposition tout au long de la journée et il vous accompagnera partout.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <strong>Une Berline pour la mariée</strong>
                        <br/>
                        En effet, pendant toute une journée, et plus si vous le souhaitez, nous mettons à votre
                        disposition l’une de nos berlines avec chauffeur. Ce dernier sera en tenue de cérémonie
                        et aura décoré la voiture avec des fleurs ou selon vos critères. Il sera alors à votre écoute
                        pour vous accompagner sur les différents lieux qui jalonneront cette journée si importante.
                        Prendre en charge le transport de la mariée ou de ses invités, coiffeur, mairie, lieu de
                        réception ou parc pour la séance photo, notre chauffeur sera se faire discret et mettra tout en
                        œuvre pour répondre à vos demandes. À la fin de la soirée, il vous accompagnera vers le
                        lieu de la nuit de noces, vous serez ainsi certains d’effectuer le trajet en toute sécurité.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        <strong>Navette pour vos invités</strong>
                        <br/>
                        Vos convives peuvent également compter sur nous pour assurer leurs transferts d’un
                        endroit à l’autre lors du grand jour. Depuis un aéroport, un hôtel, une gare ou leur domicile,
                        mettez à leur disposition un service de chauffeur privé. À la fin des festivités, nos chauffeurs
                        raccompagneront vos convives en toute sécurité à leurs lieux d'hébergement.
                    </Typography>
                </ExpandableContent>
                <ReservationButton
                    buttonText="Réserver"
                    phoneNumber={process.env.REACT_APP_BUSINESS_PHONE}
                />
            </div>
        </>
    );
}
