import React, { useState, useEffect, useLayoutEffect } from 'react';
import Autocomplete from "react-google-autocomplete";
import { TextField, Button, Typography, Snackbar, SnackbarContent, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import DateTimePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { MdDateRange, MdAccessTime } from 'react-icons/md';
import fr from 'date-fns/locale/fr';
import '../Styles/reserver.css';
import { Link, useLocation } from 'react-router-dom';
import ContactImage from "../contact2.png";

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const useStyles = makeStyles((theme) => ({
    success: {
        backgroundColor: '#4caf50',
    },
    error: {
        backgroundColor: '#f44336',
    },
    dateTimeContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1),
    },
    dateIcon: {
        marginRight: theme.spacing(1),
        fontSize: '1.5rem',
        color: theme.palette.text.secondary,
    },
    timeIcon: {
        marginRight: theme.spacing(1),
        fontSize: '1.5rem',
        color: theme.palette.text.secondary,
    },
    dateTimePicker: {
        width: '100%',
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '20px',
    },
    image: {
        maxWidth: '100%',
        height: '200px',
    },
    iconButton: {
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        fontSize: '1.5rem',
    },
}));

export function Reserver() {
    const classes = useStyles();
    const location = useLocation();

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [reservationDetails, setReservationDetails] = useState({
        firstName: '',
        nom: '',
        email: '',
        date: new Date(),
        time: (() => {
            const now = new Date();
            now.setMinutes(now.getMinutes() + 30);
            return now;
        })(),
        lieuDepart: '',
        lieuArrivee: '',
        telephone: '',
        numPassengers: 1, // Default value
        numBags: 0, // Default value
        vehicleType: 'berline', // Default value
        distance: '',
        cost: '',
        duration: ''
    });

    const [errors, setErrors] = useState({
        firstName: false,
        nom: false,
        email: false,
        telephone: false,
        lieuDepart: false,
        lieuArrivee: false,
        date: false,
        time: false,
    });

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarColor, setSnackbarColor] = useState('success');
    const [sendingReservation, setSendingReservation] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL;

    const minimumDate = new Date();
    minimumDate.setMinutes(minimumDate.getMinutes() + 30);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        let date = searchParams.get('dateDepart') || '';
        let dateDepart = date ? new Date(Date.parse(date)) : new Date();
        if (dateDepart.toString() === 'Invalid Date') {
            dateDepart = new Date();
        }

        let time = searchParams.get('heureDepart') || '';
        let timeDepart = time ? new Date(Date.parse(time)) : new Date();
        if (timeDepart.toString() === 'Invalid Date') {
            timeDepart = new Date();
        }

        setReservationDetails((prevDetails) => ({
            ...prevDetails,
            date: dateDepart,
            time: timeDepart,
            lieuDepart: searchParams.get('lieuDepart') || '',
            lieuArrivee: searchParams.get('lieuArrivee') || '',
            numPassengers: searchParams.get('numPassengers') || 1,
            numBags: searchParams.get('numBags') || 0,
            vehicleType: searchParams.get('vehicleType') || 'berline',
            distance: searchParams.get('distance') || '',
            cost: searchParams.get('cost') || '',
            duration: searchParams.get('duration') || ''
        }));
    }, [location.search]);

    useEffect(() => {
        const now = new Date();
        if (
            reservationDetails.date.getDate() === now.getDate() &&
            reservationDetails.date.getMonth() === now.getMonth() &&
            reservationDetails.date.getFullYear() === now.getFullYear()
        ) {
            const newTime = new Date();
            newTime.setMinutes(newTime.getMinutes() + 30);
            setReservationDetails((prevDetails) => ({
                ...prevDetails,
                time: newTime,
            }));
        }
    }, [reservationDetails.date]);

    const handleDateChange = (date) => {
        setReservationDetails((prevDetails) => ({ ...prevDetails, date }));
    };

    const handleTimeChange = (time) => {
        setReservationDetails((prevDetails) => ({ ...prevDetails, time }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setReservationDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSendingReservation(true);

        const requiredFields = ['firstName', 'nom', 'email', 'telephone', 'lieuDepart', 'lieuArrivee', 'date', 'time'];
        let hasErrors = false;
        const newErrors = {};

        requiredFields.forEach((field) => {
            if (!reservationDetails[field]) {
                newErrors[field] = true;
                hasErrors = true;
            } else {
                newErrors[field] = false;
            }
        });

        setErrors(newErrors);

        if (!hasErrors) {
            try {
                const config = {};

                await axios.post(`${apiUrl}/send-reservation-email`, {
                    ...reservationDetails,
                    date: reservationDetails.date.toLocaleDateString('fr-FR'),
                    time: reservationDetails.time.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' }),
                    numPassengers: reservationDetails.numPassengers,
                    numBags: reservationDetails.numBags,
                    vehicleType: reservationDetails.vehicleType,
                    distance: reservationDetails.distance,
                    cost: reservationDetails.cost,
                    duration: reservationDetails.duration
                }, config);

                setSnackbarMessage('Réservation envoyée avec succès');
                setSnackbarColor('success');
                setSnackbarOpen(true);
                setReservationDetails({
                    firstName: '',
                    nom: '',
                    email: '',
                    date: new Date(),
                    time: new Date(),
                    lieuDepart: '',
                    lieuArrivee: '',
                    telephone: '',
                    numPassengers: 1,
                    numBags: 0,
                    vehicleType: 'berline',
                    distance: '',
                    cost: '',
                    duration: ''
                });
            } catch (error) {
                console.error('Erreur lors de l\'envoi de la réservation:', error);
                setSnackbarMessage('Erreur lors de l\'envoi de la réservation');
                setSnackbarColor('error');
                setSnackbarOpen(true);
            }
        } else {
            setSnackbarMessage('Veuillez remplir tous les champs du formulaire');
            setSnackbarColor('error');
            setSnackbarOpen(true);
        }

        setSendingReservation(false);
    };

    const handleAddressSelected = (field) => (place) => {
        setReservationDetails((prevDetails) => ({
            ...prevDetails,
            [field]: place ? place.formatted_address : ''
        }));
    };

    const calculateMinTime = () => {
        const today = new Date();
        if (
            reservationDetails.date.getDate() === today.getDate() &&
            reservationDetails.date.getMonth() === today.getMonth() &&
            reservationDetails.date.getFullYear() === today.getFullYear()
        ) {
            return minimumDate;
        }
        return new Date(today.setHours(0, 0, 0, 0)); // Min time for future dates is midnight
    };

    const calculateMaxTime = () => {
        return new Date().setHours(23, 59, 59, 999); // Max time is the end of the day
    };

    const options = {
        types: ['geocode', 'establishment'],
        componentRestrictions: { country: "fr" },
    };

    return (
        <>
            <Typography variant="h4" align="center" gutterBottom>
                Réservez votre course privée
            </Typography>
            <div className={classes.imageContainer}>
                <Link to="/">
                    <img
                        src={ContactImage}
                        alt={process.env.REACT_APP_BUSINESS_NAME}
                        className={classes.image}
                    />
                </Link>
            </div>
            <Typography variant="h6" align="left" gutterBottom>
                Mes informations de réservation :
            </Typography>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            required
                            label="Prénom"
                            variant="outlined"
                            name="firstName"
                            value={reservationDetails.firstName}
                            onChange={handleInputChange}
                            error={errors.firstName}
                            helperText={errors.firstName && "Ce champ est requis"}
                            InputProps={{
                                style: { backgroundColor: 'white' }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            required
                            label="Nom"
                            variant="outlined"
                            name="nom"
                            value={reservationDetails.nom}
                            onChange={handleInputChange}
                            error={errors.nom}
                            helperText={errors.nom && "Ce champ est requis"}
                            InputProps={{
                                style: { backgroundColor: 'white' }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            label="Email"
                            variant="outlined"
                            name="email"
                            value={reservationDetails.email}
                            onChange={handleInputChange}
                            error={errors.email}
                            helperText={errors.email && "Ce champ est requis"}
                            InputProps={{
                                style: { backgroundColor: 'white' }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            required
                            label="Téléphone"
                            variant="outlined"
                            name="telephone"
                            value={reservationDetails.telephone}
                            onChange={handleInputChange}
                            error={errors.telephone}
                            helperText={errors.telephone && "Ce champ est requis"}
                            InputProps={{
                                style: { backgroundColor: 'white' }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <label className="label">Adresse de départ : </label>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Autocomplete
                                onPlaceSelected={handleAddressSelected('lieuDepart')}
                                apiKey={API_KEY}
                                options={options}
                                className="autocomplete-input"
                                defaultValue={reservationDetails.lieuDepart}
                                style={{ width: '100%', fontSize: '1.2rem' }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <label className="label">Adresse d'arrivée : </label>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Autocomplete
                                onPlaceSelected={handleAddressSelected('lieuArrivee')}
                                apiKey={API_KEY}
                                options={options}
                                className="autocomplete-input"
                                defaultValue={reservationDetails.lieuArrivee}
                                style={{ width: '100%', fontSize: '1.2rem' }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.dateTimeContainer}>
                            <MdDateRange className={classes.dateIcon} />
                            <DateTimePicker
                                selected={reservationDetails.date}
                                onChange={handleDateChange}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="Sélectionnez une date"
                                showYearDropdown
                                showMonthDropdown
                                dropdownMode="select"
                                locale={fr}
                                minDate={new Date()} // Restrict selection to future dates only
                                className={classes.dateTimePicker}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.dateTimeContainer}>
                            <MdAccessTime className={classes.timeIcon} />
                            <DateTimePicker
                                selected={reservationDetails.time}
                                onChange={handleTimeChange}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Heure"
                                dateFormat="HH:mm"
                                placeholderText="Sélectionnez l'heure"
                                locale={fr}
                                startTime={calculateMinTime()} // Start the time list at minTime
                                minTime={calculateMinTime()} // Adjust min time based on date selection
                                maxTime={calculateMaxTime()} // Provide a max time
                                className={classes.dateTimePicker}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            type="submit"
                            variant="contained"
                            className="primary-button reserver"
                            fullWidth
                            disabled={sendingReservation}
                        >
                            {sendingReservation ? 'Envoi en cours...' : 'Envoyer la réservation'}
                        </Button>
                    </Grid>
                </Grid>
            </form>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <SnackbarContent
                    className={classes[snackbarColor]}
                    message={snackbarMessage}
                />
            </Snackbar>
        </>
    );
}

export default Reserver;
