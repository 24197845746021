import React from 'react';
import { Typography, Grid, Paper, Icon, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import vtcNantes from "../vtcnantes.jpeg";
import ExpandableSection from "../Components/ExpandableText";
import ReservationButton from "../Components/ReservationButton";

// Import payment method logos
import VisaLogo from '../visa.png';
import MastercardLogo from '../mastercard.png';
import PaypalLogo from '../paypal.png';
import AmexLogo from '../amex.png';
import GpayLogo from '../gpay.png';
import ApplePayLogo from '../apay.png';

// Import necessary icons from @mui/icons-material
import AirportIcon from '@mui/icons-material/LocalAirport';
import TrainIcon from '@mui/icons-material/Train';
import SightseeingIcon from '@mui/icons-material/LocalSee';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import BusinessIcon from '@mui/icons-material/BusinessCenter';
import HotelIcon from '@mui/icons-material/Hotel';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DiamondIcon from '@mui/icons-material/Diamond';
import WomenIcon from '@mui/icons-material/Female';
import VacationIcon from '@mui/icons-material/Flight';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import CarRepairIcon from '@mui/icons-material/Build';
import SchoolIcon from '@mui/icons-material/School';
import AccessibilityIcon from '@mui/icons-material/AccessibilityNew';
import AttractionsIcon from '@mui/icons-material/Attractions';
import PowerIcon from '@mui/icons-material/Power';
import LocalDrinkIcon from '@mui/icons-material/Liquor';
import SanitizerIcon from '@mui/icons-material/Sanitizer';
import StormIcon from '@mui/icons-material/Storm';

const useStyles = makeStyles((theme) => ({
    serviceItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        padding: '5px',
        textAlign: 'center',
        marginBottom: '5px',
        transition: 'box-shadow 0.3s ease',
        '&:hover': {
            boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
        },
    },
    serviceItemMobile: {
        display: 'contents',
        width: '50%',
        height: '100%',
        padding: '5px',
        marginBottom: '5px',
        transition: 'box-shadow 0.3s ease',
        '&:hover': {
            boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
        },
    },
    equipementItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        padding: '5px',
        textAlign: 'center',
        border: '0px',
        boxShadow: '0px ',
    },
    title: {
        fontWeight: 'bold',
    },
    centerItems: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    icon: {
        fontSize: 48,
        marginTop: '5px',
    },
    container: {
        marginTop: '20px', // Adds space above the main container
        marginBottom: '30px', // Adds space below the main container
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center', // Vertical centering
        marginBottom: '20px', // Adds space below the image
    },
    image: {
        maxWidth: '1000%', // Ensures the image doesn't exceed the container's width
        width: '60%', // Ensures the image doesn't exceed the container's width
        height: 'auto', // Image adapts to the width of its container
        display: 'block', // Ensures the image is a block element
        margin: 'auto', // Centers the image horizontally
    },
    paymentLogo: {
        maxWidth: '100px', // Adjust this size based on your image size
        margin: '10px',
    },
    paymentGridItem: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
}));

const ServiceItem = ({ title, icon, route }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Grid item xs={6} sm={6} md={4} lg={4}>
            <Link to={route} style={{ textDecoration: 'none', color: 'inherit' }}>
                <Paper elevation={2} className={isSmallScreen ? classes.serviceItemMobile : classes.serviceItem}>
                    <Icon className={classes.icon}>{icon}</Icon>
                    <Typography variant="h6" gutterBottom>
                        {title}
                    </Typography>
                </Paper>
            </Link>
        </Grid>
    );
};

const EquipementItem = ({ title, icon }) => {
    const classes = useStyles();
    return (
        <Grid item xs={6} sm={3} md={3} lg={3}>
            <div className={`${classes.equipementItem} ${classes.centerItems}`}>
                <Icon className={classes.icon}>{icon}</Icon>
                <Typography variant="h6" className={classes.title}>
                    {title}
                </Typography>
            </div>
        </Grid>
    );
};

export function Services() {
    const classes = useStyles();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const services = [
        { title: 'Aéroport', route: '/aeroport', icon: <AirportIcon /> },
        { title: 'Gare', route: '/gare', icon: <TrainIcon /> },
        { title: 'Business', route: '/business', icon: <BusinessIcon /> },
        { title: 'Hôtels', route: '/hotel', icon: <HotelIcon /> },
        { title: 'Restaurants & Clubs', route: '/restaurants-clubs', icon: <RestaurantIcon /> },
        { title: 'Tourisme', route: '/tourisme', icon: <SightseeingIcon /> },
        { title: 'Plages', route: '/plage', icon: <BeachAccessIcon /> },
        { title: 'Longues distances', route: '/longues-distances', icon: <DirectionsCarIcon /> },
        { title: 'Mariages', route: '/mariage', icon: <DiamondIcon /> },
        { title: 'Femmes', route: '/femme', icon: <WomenIcon /> },
        { title: 'Voyages', route: '/voyages', icon: <VacationIcon /> },
        { title: 'Panne & Rapatriement', route: '/panne', icon: <CarRepairIcon /> },
        { title: 'Transports scolaires', route: '/transport-scolaire', icon: <SchoolIcon /> },
        { title: 'Parcs d\'attractions', route: '/parcs-attractions', icon: <AttractionsIcon /> },
        { title: 'Transport TPMR', route: '/transport-tpmr', icon: <AccessibilityIcon /> },
        // Add more services here with their respective icons and routes
    ];

    const equipements = [
        { title: 'Chargeur', icon: <PowerIcon /> },
        { title: 'Rafraîchissement', icon: <LocalDrinkIcon /> },
        { title: 'Gel hydroalcoolique', icon: <SanitizerIcon /> },
        { title: 'Friandises', icon: <StormIcon /> },
    ];

    const paymentMethods = [
        { name: 'Visa', logo: VisaLogo },
        { name: 'Mastercard', logo: MastercardLogo },
        { name: 'PayPal', logo: PaypalLogo },
        { name: 'American Express', logo: AmexLogo },
        { name: 'Apple Pay', logo: ApplePayLogo },
        { name: 'Google Pay', logo: GpayLogo },
    ];

    return (
        <>
            <Typography variant="h4" style={{ textAlign: 'center', marginBottom: '20px' }}>
                Les services de {process.env.REACT_APP_BUSINESS_NAME} Nantes
            </Typography>

            <div className={classes.imageContainer}>
                <Link to="/"> {/* Link to the root of the site */}
                    <img
                        src={vtcNantes}
                        alt={process.env.REACT_APP_BUSINESS_NAME}
                        className={classes.image}
                        style={{ maxWidth: isSmallScreen ? '100%' : '80%' }} // Adjusts image width based on screen size
                    />
                </Link>
            </div>

            <Typography variant="body1" paragraph>
                Chez {process.env.REACT_APP_BUSINESS_NAME} à Nantes, nous garantissons une expérience exceptionnelle lors de chaque déplacement. Nos chauffeurs privés VTC s'engagent à vous offrir un voyage confortable et sans soucis.
            </Typography>
            <Typography variant="body1" paragraph>
                La mise à disposition d'un chauffeur est possible sur demande.
            </Typography>
            <Typography variant="body1" paragraph>
                Chaque voiture avec chauffeur privé dispose du wifi, de chargeurs et de boissons, pour que vous passiez un excellent moment à bord.
            </Typography>

            <Grid container spacing={2} className={classes.container}>
                {equipements.map((service, index) => (
                    <EquipementItem key={index} title={service.title} icon={service.icon} />
                ))}
            </Grid>

            <Typography variant="body1" paragraph>
                Vous avez besoin d’un chauffeur privé à Nantes ? Vous êtes à la recherche d’un moyen de transport confortable, fiable et à un prix attractif ? Vous êtes au bon endroit !
            </Typography>

            <ExpandableSection>
                <Typography variant="body1" paragraph>
                    {process.env.REACT_APP_BUSINESS_NAME} vous propose des prestations de qualité pour tous vos déplacements : loisirs, business ou tourisme.
                </Typography>
                <Typography variant="body1" paragraph>
                    Lors de votre déplacement vers la gare de Nantes-Atlantique ou à l'aéroport de Nantes, nous vous déposerons au plus près de l'accès au train ou de l'accès à votre terminal d'embarquement, aussi votre chauffeur vous attendra sur le quai lors de votre arrivée en gare de Nantes-Atlantique ou dans l'aérogare avec une pancarte à votre nom lors de votre arrivée à l'aéroport de Nantes.
                </Typography>

                <Grid container spacing={2} className={classes.container}>
                    {services.map((service, index) => (
                        <ServiceItem key={index} title={service.title} icon={service.icon} route={service.route} />
                    ))}
                </Grid>
            </ExpandableSection>

            <ReservationButton buttonText="Réserver" phoneNumber={process.env.REACT_APP_BUSINESS_PHONE} />

            <Typography variant="h5" style={{ textAlign: 'center', marginTop: '20px' }}>
                Moyens de paiement acceptés
            </Typography>
            <Grid container spacing={2} justifyContent="center">
                {paymentMethods.map((method, index) => (
                    <Grid item xs={6} sm={3} md={4} key={index} className={classes.paymentGridItem}>
                        <img src={method.logo} alt={method.name} className={classes.paymentLogo} />
                    </Grid>
                ))}
            </Grid>
        </>
    );
}
