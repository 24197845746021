import React, { useState } from 'react';
import { Button } from '@mui/material';

const ExpandableContent = ({ children }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const toggleExpansion = () => setIsExpanded(!isExpanded);

    const firstChild = React.Children.toArray(children)[0];
    const otherChildren = React.Children.toArray(children).slice(1);

    return (
        <div>
            {firstChild}
            {isExpanded && otherChildren}
            <Button onClick={toggleExpansion}>
                {isExpanded ? 'Réduire' : 'Lire la suite'}
            </Button>
        </div>
    );
};

export default ExpandableContent;
