import React from "react";
import { Link, Outlet } from "react-router-dom";
import { Grid, useMediaQuery, useTheme, Container, Box } from "@mui/material";
import Footer from './Footer';
import Header from "./Header";
import ServicesFooter from "./ServicesFooter";
import Navbar from "./Navbar";
import CallButton from "./CallButton";
import logo from "../logo.png";
import '../Styles/layout.css';

export function Layout() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <div className="layout">
            {!isMobile && (
                <div className="logo-container">
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <Link to="/">
                            <img src={logo} alt={process.env.REACT_APP_BUSINESS_NAME} className="logo"/>
                        </Link>
                        <CallButton phoneNumber={process.env.REACT_APP_BUSINESS_PHONE} style={{marginTop: '10px'}}/>
                    </div>
                </div>
            )}
            <div className="content-container" style={{paddingBottom: '150px'}}>
                <Header/>
                <Navbar/>
                <main>
                    <div style={{marginTop: isMobile ? '10px' : '80px'}}>
                        <Container maxWidth="md" className="container-max-width">
                            <Grid container justifyContent="center" alignItems="center" direction="column">
                                <Grid item>
                                {isMobile && (
                                        <>
                                            <Link to="/">
                                                <img src={logo} alt={process.env.REACT_APP_BUSINESS_NAME} className="logo" />
                                            </Link>
                                            <CallButton phoneNumber={process.env.REACT_APP_BUSINESS_PHONE} />
                                        </>
                                    )}
                                </Grid>
                                <Grid item>
                                    <Box className="box-container">
                                        <Outlet />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    </div>
                </main>
                <Footer />
            </div>
            <ServicesFooter />
        </div>
    );
}

export default Layout;
