import React, {useLayoutEffect} from 'react';
import {Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import HotelImage from "../hotel.jpeg"; // Remplacer par l'image appropriée
import {Link} from 'react-router-dom';
import ReservationButton from "../Components/ReservationButton";
import ExpandableSection from "../Components/ExpandableText";

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '20px', // Ajoute de l'espace au-dessus du container principal
        marginBottom: '10px', // Ajoute de l'espace en dessous du container principal
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center', // Centrage vertical
        marginBottom: '20px', // Ajoute de l'espace en dessous de l'image
        textAlign: 'center',

    },
    image: {
        maxWidth: '80%', // Réduire la largeur de l'image sur les appareils mobiles
        height: 'auto', // Laisser la hauteur s'ajuster automatiquement pour maintenir le ratio
        maxHeight: '400px', // Hauteur maximale de l'image (à ajuster selon vos besoins)
    },
}));

export function Hotel() {
    const classes = useStyles();
    useLayoutEffect(() => {
        // Fait défiler la page vers le haut lorsque le composant est monté
        window.scrollTo(0, 0);
    }, []); // Le tableau vide [] en tant que dépendance signifie que cela ne s'exécute qu'une seule fois après le premier rendu

    return (
        <div className={classes.container}>
            <Typography variant="h4" style={{textAlign: 'center', marginBottom: '20px'}}>Transfert Hôtel</Typography>

            <div className={classes.imageContainer}>
                <Link to="/"> {/* Lien vers la racine du site */}
                    <img
                        src={HotelImage} // Utilisation de l'image appropriée
                        alt={process.env.REACT_APP_BUSINESS_NAME}
                        className={classes.image}
                    />
                </Link>
            </div>
            <ExpandableSection>
                <Typography variant="body1" paragraph>
                    Notre groupement de chauffeurs privés a une parfaite connaissance des hôtels de l’agglomération
                    nantaise et du département, avec lesquels nous travaillons régulièrement. Si vous avez choisi de
                    séjourner dans l’un de ces établissements hôteliers, vous pouvez nous contacter pour effectuer votre
                    transfert. De jour comme de nuit, nous sommes à votre disposition aussi bien pour vous emmener à
                    l’aéroport que pour vous conduire durant votre séjour vers toutes destinations de votre choix.
                </Typography>
                <Typography variant="body1" paragraph>
                    <strong>Quels sont nos avantages ?</strong> {process.env.REACT_APP_BUSINESS_NAME} assure vos
                    transferts avec tous les hôtels de la région. En conséquent, nous serons en mesure de vous conduire
                    dès votre arrivée à l’aéroport ou à la gare, jusqu’à n’importe quel hôtel ou location. Notre
                    chauffeur privé vous attendra avec une pancarte à votre nom, au nom de votre client ou d’une
                    entreprise désignée, il vous aidera à porter vos bagages, puis vous conduira à l’adresse indiquée. À
                    l'arrivée, il vous aidera à porter vos bagages.
                </Typography>
                <Typography variant="body1" paragraph>
                    <strong>Pourquoi nous choisir ?</strong> Tous nos chauffeurs ont une parfaite connaissance des
                    hôtels de Nantes et de sa région, ils vous conduiront, sans stress, ni perte de temps et d’énergie,
                    votre transfert sera effectué en toute sérénité. De plus, si vous le souhaitez, ils pourront vous
                    indiquer les lieux à découvrir et à visiter durant votre séjour.
                </Typography>
                <Typography variant="body1" paragraph>
                    Voici une liste non exhaustive de quelques hôtels que nous desservons sur Nantes :
                </Typography>
                <ul>
                    <li><a href="https://nantes-carquefou.goldentulip.com/en-us/" target="_blank"
                           rel="noopener noreferrer">Golden Tulip Nantes Carquefou</a>;
                    </li>
                    <li><a href="https://www.radissonhotels.com/fr-fr/hotels/radisson-blu-nantes" target="_blank"
                           rel="noopener noreferrer">Radisson BLU Hotel Nantes</a>;
                    </li>
                    <li><a
                        href="https://www.bestwestern.com/fr_FR/book/hotels-in-nantes/best-western-plus-hotel-de-la-regate/propertyCode.93683.html"
                        target="_blank" rel="noopener noreferrer">Best Western Plus Hotel De La Regate-Erdre</a>;
                    </li>
                    <li><a href="https://all.accor.com/hotel/B094/index.fr.shtml" target="_blank"
                           rel="noopener noreferrer">Hôtel Mercure Nantes Centre Passage Pommeraye</a>;
                    </li>
                    <li><a href="https://all.accor.com/hotel/1985/index.en.shtml" target="_blank"
                           rel="noopener noreferrer">Mercure Nantes Centre Grand Hotel</a>;
                    </li>
                    <li><a href="https://www.oceaniahotels.com/escale-oceania-nantes-aeroport" target="_blank"
                           rel="noopener noreferrer">Escale Oceania Nantes</a>;
                    </li>
                    <li><a href="https://www.oceaniahotels.com/oceania-nantes-aeroport" target="_blank"
                           rel="noopener noreferrer">Hôtel Oceania Nantes aéroport</a>;
                    </li>
                    <li><a href="https://www.oceaniahotels.com/en/oceania-hotel-de-france-nantes" target="_blank"
                           rel="noopener noreferrer">Oceania hôtel de France</a>;
                    </li>
                    <li><a href="https://all.accor.com/hotel/0410/index.en.shtml" target="_blank"
                           rel="noopener noreferrer">Novotel Nantes Carquefou</a>;
                    </li>
                    <li><a href="https://www.hotel-duquesne-nantes.com/" target="_blank" rel="noopener noreferrer">Logis
                        Hôtel Duquesne</a>.
                    </li>
                </ul>
                <Typography variant="body1" paragraph>
                    Pour profiter pleinement de la région lors de votre visite, réservez dès maintenant notre service de
                    tourisme.
                </Typography>
            </ExpandableSection>
            <ReservationButton
                buttonText="Réserver"
                phoneNumber={process.env.REACT_APP_BUSINESS_PHONE}
            />
        </div>
    );
}
