import React, {useLayoutEffect} from 'react';
import {Typography} from '@mui/material';
import {Link} from 'react-router-dom';
import {makeStyles} from '@mui/styles';
import BusinessImage from "../business.jpeg";
import ReservationButton from "../Components/ReservationButton";
import ExpandableSection from "../Components/ExpandableText";

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '20px', // Ajoute de l'espace au-dessus du container principal
        marginBottom: '10px', // Ajoute de l'espace en dessous du container principal
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center', // Centrage vertical
        marginBottom: '20px', // Ajoute de l'espace en dessous de l'image
        textAlign: 'center', // Centre horizontalement le contenu
    },
    image: {
        maxWidth: '100%', // Assurez-vous que l'image ne dépasse pas la largeur du conteneur
        height: 'auto', // Hauteur ajustée automatiquement pour maintenir le ratio
        maxHeight: '400px', // Hauteur maximale de l'image (à ajuster selon vos besoins)
    },
}));

export function Business() {
    const classes = useStyles();

    useLayoutEffect(() => {
        // Fait défiler la page vers le haut lorsque le composant est monté
        window.scrollTo(0, 0);
    }, []); // Le tableau vide [] en tant que dépendance signifie que cela ne s'exécute qu'une seule fois après le premier rendu


    return (
        <>
            <Typography variant="h4" style={{textAlign: 'center', marginBottom: '20px'}}>Chauffeur privé pour vos
                déplacements Business</Typography>
            <div className={classes.imageContainer}>
                <Link to="/"> {/* Lien vers la racine du site */}
                    <img
                        src={BusinessImage}
                        alt={process.env.REACT_APP_BUSINESS_NAME}
                        className={classes.image}
                        style={{maxWidth: '80%'}} // Ajuste la largeur de l'image sur les appareils mobiles
                    />
                </Link>
            </div>
            <ExpandableSection>
                <Typography variant="body1" paragraph>
                    Pour répondre à la demande de notre clientèle professionnelle, nous avons mis en place un service de
                    transport Business qui leur est tout particulièrement dédié. Ce service unique est parfait pour
                    réaliser des rendez-vous professionnels dans l’agglomération nantaise et sa région.
                </Typography>
                <Typography variant="body1" paragraph>
                    En choisissant notre service Business, vous pourrez choisir votre véhicule parmi notre flotte. De la
                    berline classique au VAN, notre offre nous permet de nous adapter à chacune de vos demandes. Quel
                    que soit le véhicule choisi, vous bénéficierez d’un grand confort et ce sera le lieu idéal pour
                    échanger avec vos partenaires commerciaux, ou préparer vos rendez-vous durant le trajet.
                </Typography>
                <Typography variant="body1" paragraph>
                    Vous pouvez bénéficier de notre service pendant la durée de votre choix. Une heure, une journée, une
                    semaine, c’est aux grès de vos envies et selon vos besoins. Notre chauffeur restera à votre
                    disposition, et vous permettra d’offrir un accueil rassurant à vos clients et autres partenaires
                    d’affaires. Pour les transferts récurrents, une facturation mensuelle pourra être mise en place.
                </Typography>
                <Typography variant="body1" paragraph>
                    Nos prestations sont bien étudiées afin de répondre aux exigences des entreprises, dirigeants
                    d’entreprises, hommes ou femmes d’affaires. Nous sommes ainsi en mesure de vous octroyer un service
                    sur-mesure. Lors de vos déplacements, un chauffeur business et un véhicule haut de gamme seront à
                    votre disposition pour vos rendez-vous d’affaires. Nous assurons vos transferts de et vers les gares
                    et aéroports.
                </Typography>
                <Typography variant="body1" paragraph>
                    Le succès de votre trajet et votre satisfaction sont au cœur de notre préoccupation, pour se
                    faire {process.env.REACT_APP_BUSINESS_NAME} vous propose un service spécialement dédié aux
                    professionnels et aux voyages d’affaires. Pour tous vos déplacements business, vous avez besoin d’un
                    chauffeur fiable et disponible, quel que soit l’heure, qui maîtrise aussi les attitudes à avoir
                    durant le trajet et aux besoins une langue étrangère. Il a une connaissance parfaite de la région,
                    vous assure d’être à l’heure en toutes circonstances à vos divers rendez-vous, et soit parfaitement
                    opérationnel, peu importe votre nombre d’étapes ou leurs localisations.
                </Typography>
                <Typography variant="body1" paragraph>
                    Votre chauffeur privé vous offre professionnalisme et implication. Il vous attendra d’un rendez-vous
                    à l’autre, et peut être à votre entière disposition pour la journée entière. Grâce à nos véhicules,
                    vous profitez d’un chargeur pour vos appareils électroniques à bord, et d’un confort optimal, vous
                    permettant de mettre à profit le trajet pour peaufiner vos rendez-vous.
                </Typography>
                <Typography variant="body1" paragraph>
                    Nous sommes bien plus qu'un simple service de transport. Nous sommes votre partenaire de confiance
                    pour tous vos déplacements.
                </Typography>
                <Typography variant="body1" paragraph>
                    Notre engagement :
                    <ul>
                        <li>Vous offrir un service de qualité supérieure</li>
                        <li>Répondre à vos besoins spécifiques</li>
                        <li>Vous garantir un trajet sûr et confortable</li>
                        <li>Dépasser vos attentes</li>
                    </ul>
                </Typography>
            </ExpandableSection>
            <ReservationButton
                buttonText="Réserver"
                phoneNumber={process.env.REACT_APP_BUSINESS_PHONE}
            />
        </>
    );
}
