import React, {useLayoutEffect} from 'react';
import {Typography} from '@mui/material';
import {Link} from "react-router-dom";
import {makeStyles} from '@mui/styles';
import QuiSommesNOus from "../quisommesnous.png";

import ReservationButton from "../Components/ReservationButton";
import ExpandableSection from "../Components/ExpandableText";

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '20px',
        marginBottom: '10px',
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '20px',
        textAlign: 'center',
    },
    image: {
        maxWidth: '60%',
        height: 'auto',
        maxHeight: '50vh',
    }
}));

export function QuiSommesNous() {
    const classes = useStyles();

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Typography variant="h4" style={{textAlign: 'center', marginBottom: '20px'}}>À propos de nous</Typography>
            <div className={classes.imageContainer}>
                <Link to="/"> {/* Lien vers la racine du site */}
                    <img
                        src={QuiSommesNOus}
                        alt={process.env.REACT_APP_BUSINESS_NAME}
                        className={classes.image}
                    />
                </Link>
            </div>
            <ExpandableSection>
                <Typography variant="body1" paragraph>
                    Bien plus qu'un simple service de transport, nous sommes votre partenaire de confiance pour tous vos déplacements.
                </Typography>
                <Typography variant="body1" paragraph>
                    {process.env.REACT_APP_BUSINESS_NAME} c'est un groupe de 50 chauffeurs privés "Grande remise".
                    Notre expérience et notre enthousiasme associés à une volonté forte nous ont permis d'élaborer une charte qualité stricte avec l'ambition d'être le groupe de chauffeurs privés le plus qualitatif sur Nantes et sa région.
                </Typography>
                <Typography variant="body1" paragraph>
                    Fondée avec une passion pour le service client, notre équipe de chauffeurs privés professionnels et dévoués s'engage à offrir une expérience de transport premium. Nous mettons à votre disposition notre expertise, notre ponctualité et notre flotte de véhicules haut de gamme.
                </Typography>
                <Typography variant="body1" paragraph>
                    La palette de services que nous vous proposons en tant que chauffeurs privés est riche et variée. Que vos déplacements soient d'ordre privé ou professionnel, qu'ils soient ponctuels ou récurrents, nous sommes à votre disposition 24h/24, 7j/7, pour des prestations adaptées à vos besoins.
                </Typography>
                <Typography variant="body1" paragraph>
                    La qualité de nos prestations, le sérieux de nos chauffeurs privés, nos véhicules haut de gamme et notre large palette de services vous garantissent un trajet confortable, sûr et convivial à la hauteur de vos exigences.
                </Typography>
                <Typography variant="body1" paragraph>
                    Nos chauffeurs sont des professionnels expérimentés ayant un sens aigu du service et une maîtrise parfaite de la route. Toujours en costume cravate, ils sauront vous satisfaire avec une prestation irréprochable.
                </Typography>
                <Typography variant="body1" paragraph>
                    Pour vos déplacements, optez pour un moyen de transport sécurisé et fiable. Notre service de transfert remplit parfaitement ces critères. En effet, tout en profitant du confort de nos véhicules, vous apprécierez de confier la logistique liée à l'itinéraire, la circulation ou encore au stationnement à un professionnel expérimenté.
                </Typography>
                <Typography variant="body1" paragraph>
                    Notre engagement :
                    <ul>
                        <li>vous offrir un service de qualité supérieure;</li>
                        <li>répondre à vos besoins spécifiques;</li>
                        <li>vous garantir un trajet sûr et confortable;</li>
                        <li>dépasser vos attentes.</li>
                    </ul>
                </Typography>
                <Typography variant="body1" paragraph>
                    Choisissez {process.env.REACT_APP_BUSINESS_NAME} et vivez une expérience de transport unique !
                </Typography>
            </ExpandableSection>
            <ReservationButton
                buttonText="Réserver"
                phoneNumber={process.env.REACT_APP_BUSINESS_PHONE}
            />
        </>
    );
}
